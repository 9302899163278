import type { Result } from "@/utils/result";
import { err, ok, tryToResult } from "@/utils/result";
import type { ImageSourceSetsRecord } from "@/types/of-image.types";
import { DEFAULT_SOURCE_SETS } from "@/constants/of-image.constant";
import { ImageRatio } from "@/constants/image-ratio.constants";
import type { Immutable } from "@/types/immutable";
import { assertNonNullable } from "@/utils/assertNonNullable";
import { envBasedConfig } from "@/constants/env-based-config.constant";
import { isAbsoluteUrl } from "@/utils/isAbsoluteUrl";

const enum ImageServiceQueryParam {
  DevicePixelRatio = "dpr",
  ImageSrc = "image",
  Height = "h",
  Quality = "q",
  Width = "w",
}

export const imageUrlService = {
  buildImageSourceSetsFromUrl(
    lightModeUrl: string,
  ): Result<ImageSourceSetsRecord> {
    const { mobile, tabletPortrait, tabletLandscape, desktop } =
      DEFAULT_SOURCE_SETS;

    return tryToResult(() => {
      return {
        mobile: this.buildImgSrc({
          ...DEFAULT_SOURCE_SETS.mobile,
          src: lightModeUrl,
        }).unwrap(),
        tabletPortrait: this.buildImgSrc({
          ...DEFAULT_SOURCE_SETS.tabletPortrait,
          src: lightModeUrl,
        }).unwrap(),
        tabletLandscape: this.buildImgSrc({
          ...DEFAULT_SOURCE_SETS.tabletLandscape,
          src: lightModeUrl,
        }).unwrap(),
        desktop: this.buildImgSrc({
          ...DEFAULT_SOURCE_SETS.desktop,
          src: lightModeUrl,
        }).unwrap(),
      };
    }).map((urls) => {
      return {
        mobile: {
          ...mobile,
          lightModeUrl: urls.mobile,
          darkModeUrl: urls.mobile,
        },
        tabletPortrait: {
          ...tabletPortrait,
          lightModeUrl: urls.tabletPortrait,
          darkModeUrl: urls.tabletPortrait,
        },
        tabletLandscape: {
          ...tabletLandscape,
          lightModeUrl: urls.tabletLandscape,
          darkModeUrl: urls.tabletLandscape,
        },
        desktop: {
          ...desktop,
          lightModeUrl: urls.desktop,
          darkModeUrl: urls.desktop,
        },
      };
    });
  },

  /**
   * Builds the image src with proper image-service settings.
   * Should be used for all non-SVG images.
   */
  buildImgSrc({
    quality,
    ratio = ImageRatio.Default3to2,
    src,
    width,
  }: Immutable<{
    quality?: number;
    ratio?: number;
    src: string;
    width: number;
  }>): Result<string> {
    const isLocalImage = !isAbsoluteUrl(src);

    if (isLocalImage) {
      return ok(src); // no need to transform local images from the /assets folder
    }

    const isOfImageServiceImage = src.startsWith(
      envBasedConfig.imageServiceUrl,
    );

    const encodedImageParamResult = tryToResult(() => {
      return isOfImageServiceImage
        ? assertNonNullable(new URL(src).searchParams.get("image"))
        : encodeURIComponent(src);
    });

    return encodedImageParamResult.map((imageParam) => {
      const url = new URL(`${envBasedConfig.imageServiceUrl}/transform`); // does not throw

      url.searchParams.set(ImageServiceQueryParam.Width, width.toString());

      if (ratio <= 0) {
        return err("ratio should be greater than 0");
      }

      url.searchParams.set(
        ImageServiceQueryParam.Height,
        Math.round(width / ratio).toString(),
      );

      if (quality !== undefined) {
        url.searchParams.set(
          ImageServiceQueryParam.Quality,
          quality.toString(),
        );
      }

      url.searchParams.set(ImageServiceQueryParam.DevicePixelRatio, "2");

      url.searchParams.set(ImageServiceQueryParam.ImageSrc, imageParam);

      return url.href;
    });
  },
};
