/**
 * Simplified monad `Either` from functional languages, somewhat similar to
 * `Result` from Rust
 * Useful to wrap dangerous code which may throw or return broken data, e.g.:
 * parsing, network calls, invoking 3rd party libraries etc.
 */

/**
 * Wraps throwing function into Result to avoid exceptions spread.
 * Nested Results are flattened
 */
export function tryToResult<T>(fn: () => T | Result<T>): Result<T> {
  try {
    const result = fn();

    if (isResult(result)) {
      return result;
    }

    return ok(result);
  } catch (error: unknown) {
    return err(error);
  }
}

export class Ok<T> {
  readonly tag = "ok";
  readonly value: T;

  constructor(value: T) {
    this.value = value;
  }

  isOk(): this is Ok<T> {
    return true;
  }

  isErr(): this is Err<T> {
    return false;
  }

  map<ProcessedT>(
    fn: (v: T) => ProcessedT | Result<ProcessedT>,
  ): Result<ProcessedT> {
    return tryToResult(() => fn(this.value));
  }

  /**
   * Throws exception for Err variant of Result.
   * ⚠️ Use with caution! Ideally, it should be wrapped with tryToResult()
   */
  unwrap(): T {
    return this.value;
  }
}

export function ok<T>(value: T): Ok<T> {
  return new Ok(value);
}

export class Err<T> {
  readonly tag = "err";
  readonly error: Error;

  constructor(error: Error) {
    this.error = error;
  }

  isOk(): this is Ok<T> {
    return false;
  }

  isErr(): this is Err<T> {
    return true;
  }

  map<ProcessedT>(
    _: (v: T) => ProcessedT | Result<ProcessedT>,
  ): Result<ProcessedT> {
    return err<ProcessedT>(this.error);
  }

  /**
   * Throws exception for Err variant of Result.
   * ⚠️ Use with caution! Ideally, it should be wrapped with tryToResult()
   */
  unwrap(): T {
    throw this.error;
  }
}

export function err<T>(value: unknown): Err<T> {
  if (value instanceof Error) {
    return new Err(value);
  }

  return new Err(Error(String(value)));
}

export type Result<T> = Ok<T> | Err<T>;

export function isResult<T>(value: unknown): value is Result<T> {
  return value instanceof Ok || value instanceof Err;
}

export function isOkResult<T>(value: Result<T>): value is Ok<T> {
  return value instanceof Ok;
}

export function isErrorResult<T>(value: unknown): value is Err<T> {
  return value instanceof Err;
}
