import type { FC } from "react";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import classNames from "classnames";

import styles from "./EntityLogo.module.scss";

export const EntityLogo: FC<{
  src: string;
  alt: string;
  darkModeSrc?: string;
  withHover?: boolean;
  isPreloaded?: boolean;
  className?: string;
  size?: number;
}> = (props) => {
  const {
    src,
    alt,
    withHover = false,
    isPreloaded = false,
    className,
    darkModeSrc,
    size = 64,
  } = props;
  return (
    <span
      className={classNames(
        styles.entityLogo,
        {
          [String(styles.entityLogoWithHover)]: withHover,
        },
        className,
      )}
    >
      <ImageWithFallback
        src={src}
        fallbackImageSrc="https://images.onefootball.com/cw/icons/entity-logo-placeholder.svg"
        alt={alt}
        isPreloaded={isPreloaded}
        width={size}
        height={size}
        className={styles.entityLogoImage}
        darkModeSrc={darkModeSrc}
      />
    </span>
  );
};
