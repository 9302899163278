import type { FC } from "react";
import type { TitleComponent } from "@motain/xpa-proto-files-web/lib/types/title";
import { TitleComponent_Weight } from "@motain/xpa-proto-files-web/lib/types/title";
import styles from "./Title.module.scss";
import { HorizontalSeparator } from "@/xpa-components/horizontal-separator/HorizontalSeparator";
import classNames from "classnames";

const WeightToClassMap = {
  [TitleComponent_Weight.BOLD]: "bold",
  [TitleComponent_Weight.REGULAR]: "regular",
  [TitleComponent_Weight.UNRECOGNIZED]: "regular",
};

type TitleProps = TitleComponent & {
  className?: string;
  align?: "center" | "left";
};

export const Title: FC<TitleProps> = (props) => {
  const {
    hasDelimiter,
    size,
    weight,
    text,
    className,
    align = "center",
  } = props;

  const textClassName = `title-${size}-${WeightToClassMap[weight]}`;

  return (
    <>
      <h2
        className={classNames(
          className,
          textClassName,
          align === "left" ? styles.leftAlign : styles.container,
        )}
      >
        {text}
      </h2>
      {hasDelimiter && <HorizontalSeparator className={styles.delimiter} />}
    </>
  );
};
