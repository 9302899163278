type RecordEntries<TRecord> = Array<
  {
    [K in keyof TRecord]: [K, TRecord[K]];
  }[keyof TRecord]
>;

/**
 * Typed version of `Object.entries` for `Record`s
 */
export function getRecordEntries<TRecord extends Record<string, unknown>>(
  record: TRecord,
): RecordEntries<TRecord> {
  const res: RecordEntries<TRecord> = [];

  for (const k in record) {
    if (Object.prototype.hasOwnProperty.call(record, k)) {
      res.push([k, record[k]]);
    }
  }

  return res;
}
