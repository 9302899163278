import type { FC } from "react";
import React from "react";
import classnames from "classnames";
import type { SectionHeaderComponent } from "@motain/xpa-proto-files-web/lib/types/section_header";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { isNonNullable } from "@/types/isNonNullable";
import { EntityLogo } from "@/components/entity-logo/EntityLogo";
import styles from "./SectionHeader.module.scss";
import { isNullable } from "@/types/isNullable";
import { Title } from "@/xpa-components/title/Title";
import {
  TitleComponent_Size,
  TitleComponent_Weight,
} from "@motain/xpa-proto-files-web/lib/runtime-json/title";
import Link from "next/link";

export const SectionHeader: FC<SectionHeaderComponent> = (props) => {
  const { title, subtitle, entityLink, entityLogo } = props;

  const sectionHeaderTitle = {
    text: title,
    size: isNonNullable(entityLogo)
      ? TitleComponent_Size.SIX
      : TitleComponent_Size.FIVE,
    weight: TitleComponent_Weight.BOLD,
    hasDelimiter: false,
  };

  return (
    <div className={styles.container}>
      {isNonNullable(entityLogo) &&
        (isNonNullable(entityLink) ? (
          <Link
            className={styles.link}
            href={entityLink.urlPath}
            onClick={() => {
              trackingService.sendXpaTracking(
                entityLink.trackingEvents,
                EventType.EVENT_CLICK,
              );
            }}
          >
            <span className="screen-reader-only">{entityLink.name}</span>
            <EntityLogo
              alt={entityLogo.alt}
              src={entityLogo.path}
              className={styles.logo}
              withHover
            />
          </Link>
        ) : (
          <div className={styles.logo__wrapper}>
            <EntityLogo
              alt={entityLogo.alt}
              src={entityLogo.path}
              className={styles.logo}
            />
          </div>
        ))}
      <div className={styles.title}>
        {isNonNullable(title) && <Title align="left" {...sectionHeaderTitle} />}
        {subtitle && (
          <h3
            className={classnames(
              "title-7-medium",
              styles.subtitle,
              isNullable(entityLogo) && "title-7-bold",
              isNonNullable(entityLogo) && "title-6-bold",
            )}
          >
            {subtitle}
          </h3>
        )}
      </div>
    </div>
  );
};
