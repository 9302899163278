import type { Immutable } from "@/types/immutable";
import type { XpaScreenSize } from "@/types/xpaScreenSize";
import type {
  ImageSourceSetsRecordWithoutUrl,
  ImageSourceSetWithoutUrl,
} from "@/types/of-image.types";
import { MEDIA_QUERY } from "./media-query.constant";
import { ImageRatio } from "./image-ratio.constants";

export const DEFAULT_WIDTH_PER_SCREEN_SIZE: Immutable<
  Record<XpaScreenSize, number>
> = {
  mobile: 280,
  tabletPortrait: 336,
  tabletLandscape: 620,
  desktop: 840,
};

const DEFAULT_IMAGE_SOURCE_SET_DESKTOP: ImageSourceSetWithoutUrl = {
  media: MEDIA_QUERY.desktop,
  width: DEFAULT_WIDTH_PER_SCREEN_SIZE.desktop,
  ratio: ImageRatio.Default3to2,
};

const DEFAULT_IMAGE_SOURCE_SET_TABLET_LANDSCAPE: ImageSourceSetWithoutUrl = {
  media: MEDIA_QUERY.tabletLandscape,
  width: DEFAULT_WIDTH_PER_SCREEN_SIZE.tabletLandscape,
  ratio: ImageRatio.Default3to2,
};

const DEFAULT_IMAGE_SOURCE_SET_TABLET_PORTRAIT: ImageSourceSetWithoutUrl = {
  media: MEDIA_QUERY.tabletPortrait,
  width: DEFAULT_WIDTH_PER_SCREEN_SIZE.tabletPortrait,
  ratio: ImageRatio.Default3to2,
};

const DEFAULT_IMAGE_SOURCE_SET_MOBILE: ImageSourceSetWithoutUrl = {
  media: MEDIA_QUERY.mobile,
  width: DEFAULT_WIDTH_PER_SCREEN_SIZE.mobile,
  ratio: ImageRatio.Default3to2,
};

export const DEFAULT_SOURCE_SETS: ImageSourceSetsRecordWithoutUrl = {
  desktop: DEFAULT_IMAGE_SOURCE_SET_DESKTOP,
  tabletLandscape: DEFAULT_IMAGE_SOURCE_SET_TABLET_LANDSCAPE,
  tabletPortrait: DEFAULT_IMAGE_SOURCE_SET_TABLET_PORTRAIT,
  mobile: DEFAULT_IMAGE_SOURCE_SET_MOBILE,
};
