import Head from "next/head";
import type {
  LinkTag,
  MetaTag,
} from "@motain/xpa-proto-files-web/lib/types/xpa";
import type { FC } from "react";

export interface LayoutHeadProps {
  pageTitle: string;
  metaTags: Array<MetaTag>;
  linkTags: Array<LinkTag>;
  jsonLd?: Array<string>;
}

const XpaLayoutHead: FC<LayoutHeadProps> = ({
  pageTitle,
  metaTags,
  linkTags,
  jsonLd,
}) => {
  return (
    <Head>
      <title>{pageTitle}</title>
      {metaTags.map(({ name, content, property }) => {
        // empty value is omitted on the frontend
        const tag = {
          content,
          ...(name && { name }),
          ...(property && { property }),
        };
        return <meta key={name + property} {...tag} />;
      })}

      {linkTags.map(({ rel, href, hrefLang }) => {
        // empty value is omitted on the frontend
        const tag = {
          rel,
          href,
          ...(hrefLang && { hrefLang }),
        };
        return <link key={rel + href} {...tag} />;
      })}

      {jsonLd?.map((jsonLdItem, index) => {
        return (
          <script
            key={`jsonLd-object-${index}`}
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `
              ${jsonLdItem}
              `,
            }}
          />
        );
      })}
    </Head>
  );
};

export { XpaLayoutHead };
